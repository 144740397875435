import { createClient } from '@supabase/supabase-js';
import type { Database } from '../types/supabase';
import { SUPABASE_CONFIG } from '../config/supabase';

const { url, anonKey, auth } = SUPABASE_CONFIG;

if (!url || !anonKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient<Database>(url, anonKey, {
  auth
});