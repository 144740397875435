import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { SupabaseService } from '../services/SupabaseService';
import type { User } from '@supabase/supabase-js';

interface UserProfile {
  name: string;
  email: string;
  newsletter?: boolean;
}

interface AuthContextType {
  currentUser: User | null;
  userProfile: UserProfile | null;
  signUp: (email: string, password: string, name: string) => Promise<void>;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  loading: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const supabaseService = SupabaseService.getInstance();

  const loadUserProfile = async (user: User) => {
    try {
      const profile = await supabaseService.getUserProfile(user.id);
      
      if (profile) {
        setUserProfile({
          name: profile.name || user.user_metadata?.name || '',
          email: profile.email || user.email || '',
          newsletter: profile.newsletter ?? true
        });
        return;
      }

      // Create default profile if none exists
      const defaultProfile = {
        name: user.user_metadata?.name || '',
        email: user.email || '',
        newsletter: true
      };

      await supabaseService.saveUserProfile(user.id, defaultProfile);
      setUserProfile(defaultProfile);
    } catch (error) {
      console.error('Error loading user profile:', error);
      throw error;
    }
  };

  useEffect(() => {
    // Set up auth state listener
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      setCurrentUser(session?.user || null);
      
      if (session?.user) {
        try {
          await loadUserProfile(session.user);
        } catch (error) {
          console.error('Error in auth state change:', error);
          setUserProfile({
            name: session.user.user_metadata?.name || '',
            email: session.user.email || '',
            newsletter: true
          });
        }
      } else {
        setUserProfile(null);
      }
      
      setLoading(false);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const signUp = async (email: string, password: string, name: string) => {
    const { data: { user }, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          name
        }
      }
    });

    if (error) throw error;
    if (!user) throw new Error('No user returned after signup');

    // Create user profile
    await supabaseService.saveUserProfile(user.id, {
      name,
      email: user.email || email,
      newsletter: true
    });
  };

  const signIn = async (email: string, password: string) => {
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password
    });

    if (error) throw error;
  };

  const signOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
    setUserProfile(null);
    window.location.reload();
  };

  const value = {
    currentUser,
    userProfile,
    signUp,
    signIn,
    signOut,
    loading
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? (
        <div className="min-h-screen bg-mindcally-dark-background flex items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-mindcally-lime"></div>
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};