import React, { useState, useEffect } from 'react';
import { AuthPages } from './components/AuthPages';
import { Navbar } from './components/Navbar';
import { Banner } from './components/Banner';
import { DateStrip } from './components/DateStrip';
import { HabitList } from './components/HabitList';
import { GoalList } from './components/GoalList';
import { GoalModal } from './components/GoalModal';
import { NewHabitModal } from './components/NewHabitModal';
import { StatsView } from './components/StatsView';
import { PauseView } from './components/PauseView';
import { SettingsView } from './components/SettingsView';
import { SpacesView } from './components/SpacesView';
import { Coach } from './components/Coach';
import { Toast } from './components/Toast';
import { useAuth } from './contexts/AuthContext';
import { SupabaseService } from './services/SupabaseService';
import type { Habit, Goal, HabitCompletion, GoalProgress } from './types';

export const App: React.FC = () => {
  const { currentUser, userProfile, signOut } = useAuth();
  const [showCoach, setShowCoach] = useState(true);
  const [habits, setHabits] = useState<Habit[]>([]);
  const [goals, setGoals] = useState<Goal[]>([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentView, setCurrentView] = useState<ViewType>('home');
  const [showNewHabitModal, setShowNewHabitModal] = useState(false);
  const [showGoalModal, setShowGoalModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [habitHistory] = useState<HabitCompletion[]>([]);
  const [goalProgress] = useState<GoalProgress[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const supabaseService = SupabaseService.getInstance();

  // Load user data when authenticated
  useEffect(() => {
    const loadUserData = async () => {
      setIsLoading(true);
      setShowToast(false);

      if (currentUser) {
        try {
          // Maximum retries for loading data
          const maxRetries = 3;
          let currentTry = 0;
          let success = false;

          while (currentTry < maxRetries && !success) {
            try {
              // Force token refresh on each try
              await currentUser.getIdToken(true);
              
              // Wait longer between each retry
              await new Promise(resolve => setTimeout(resolve, 1000 * (currentTry + 1)));

              const [userHabits, userGoals] = await Promise.all([
                supabaseService.getHabits(currentUser.id),
                supabaseService.getGoals(currentUser.id)
              ]);

              setHabits(userHabits);
              setGoals(userGoals);
              success = true;
              console.log('User data loaded successfully');
            } catch (error) {
              console.log(`Retry ${currentTry + 1}/${maxRetries} failed:`, error);
              currentTry++;
              
              if (currentTry === maxRetries) {
                throw error;
              }
            }
          }
        } catch (error) {
          console.error('Error loading user data:', error);
          
          // Handle specific error cases
          if (error instanceof Error) {
            if (error.message.includes('permission-denied') || 
                error.message.includes('Missing or insufficient permissions')) {
              setShowToast(true);
              setToastMessage('Permission error. Please try signing out and back in.');
            } else if (error.message.includes('not-found')) {
              setShowToast(true);
              setToastMessage('Could not find your data. Please refresh the page.');
            } else {
              setShowToast(true);
              setToastMessage('Failed to load your data. Please try again later.');
            }
          } else {
            setShowToast(true);
            setToastMessage('An unexpected error occurred. Please try again.');
          }
          
          // Set empty arrays on error to prevent undefined errors
          setHabits([]);
          setGoals([]);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };
    loadUserData();
  }, [currentUser]);

  const handleAddHabit = async (habit: Omit<Habit, 'id' | 'completed'>) => {
    if (!currentUser) return;

    try {
      const savedHabit = await supabaseService.saveHabit(currentUser.id, habit);
      setHabits(prev => [...prev, savedHabit]);
      setShowToast(true);
      setToastMessage(`New habit "${habit.name}" created!`);
    } catch (error) {
      console.error('Error saving habit:', error);
      setShowToast(true);
      setToastMessage('Failed to save habit. Please try again.');
    }
  };

  const handleAddGoal = async (goal: Omit<Goal, 'id' | 'current'>) => {
    if (!currentUser) return;

    try {
      const savedGoal = await supabaseService.saveGoal(currentUser.id, goal);
      setGoals(prev => [...prev, savedGoal]);
      setShowToast(true);
      setToastMessage(`New goal "${goal.name}" created!`);
    } catch (error) {
      console.error('Error saving goal:', error);
      setShowToast(true);
      setToastMessage('Failed to save goal. Please try again.');
    }
  };

  const handleLogout = async () => {
    try {
      await signOut();
      setShowCoach(true);
      setHabits([]);
      setGoals([]);
    } catch (error) {
      console.error('Error signing out:', error);
      setShowToast(true);
      setToastMessage('Failed to sign out. Please try again.');
    }
  };

  if (!currentUser || !userProfile) {
    return <AuthPages />;
  }
  
  if (isLoading) {
    return (
      <div className="min-h-screen bg-mindcally-dark-background flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-mindcally-lime"></div>
      </div>
    );
  }

  const completionRate = habits.length > 0
    ? Math.round((habits.filter(h => h.completed).length / habits.length) * 100)
    : 0;

  if (showCoach) {
    return (
      <Coach
        habits={habits}
        goals={goals}
        userName={userProfile.name}
        onContinue={() => setShowCoach(false)}
        onAddHabit={handleAddHabit}
        onAddGoal={handleAddGoal}
      />
    );
  }

  return (
    <div className="app-container">
      <Navbar
        currentView={currentView}
        onViewChange={setCurrentView}
      />

      {currentView === 'home' && (
        <div className="main-scroll-area">
          <Banner
            userName={userProfile.name}
            completionRate={completionRate}
            totalHabits={habits.length}
            completedHabits={habits.filter(h => h.completed).length}
          />

          <div className="p-4 space-y-6">
            <DateStrip
              selectedDate={selectedDate}
              onDateSelect={setSelectedDate}
            />

            <div className="space-y-6">
              <section>
                <h2 className="text-xl font-semibold mb-4 text-mindcally-navy dark:text-white">
                  Daily Habits
                </h2>
                <HabitList
                  habits={habits}
                  onToggle={async (id) => {
                    if (!currentUser) return;
                    const updatedHabits = habits.map(h =>
                      h.id === id ? { ...h, completed: !h.completed } : h
                    );
                    const updatedHabit = updatedHabits.find(h => h.id === id);
                    if (updatedHabit) {
                      try {
                        await supabaseService.updateHabit(currentUser.id, id, {
                          completed: updatedHabit.completed
                        });
                        setHabits(updatedHabits);
                      } catch (error) {
                        console.error('Error updating habit:', error);
                        setShowToast(true);
                        setToastMessage('Failed to update habit. Please try again.');
                      }
                    }
                  }}
                  onRemove={async (id) => {
                    if (!currentUser) return;
                    try {
                      await supabaseService.deleteHabit(currentUser.id, id);
                      setHabits(prev => prev.filter(h => h.id !== id));
                      setShowToast(true);
                      setToastMessage('Habit removed successfully');
                    } catch (error) {
                      console.error('Error removing habit:', error);
                      setShowToast(true);
                      setToastMessage('Failed to remove habit. Please try again.');
                    }
                  }}
                  onAdd={() => setShowNewHabitModal(true)}
                  onSetReminder={async (habitId, time) => {
                    if (!currentUser) return;
                    try {
                      await supabaseService.updateHabit(currentUser.id, habitId, {
                        reminderTime: time
                      });
                      setHabits(prev =>
                        prev.map(h =>
                          h.id === habitId ? { ...h, reminderTime: time } : h
                        )
                      );
                      setShowToast(true);
                      setToastMessage('Reminder set successfully');
                    } catch (error) {
                      console.error('Error setting reminder:', error);
                      setShowToast(true);
                      setToastMessage('Failed to set reminder. Please try again.');
                    }
                  }}
                />
              </section>

              <section>
                <GoalList
                  goals={goals}
                  onEditGoal={(goal) => {
                    setShowGoalModal(true);
                  }}
                  onDeleteGoal={async (goalId) => {
                    if (!currentUser) return;
                    try {
                      await supabaseService.deleteGoal(currentUser.id, goalId);
                      setGoals(prev => prev.filter(g => g.id !== goalId));
                      setShowToast(true);
                      setToastMessage('Goal removed successfully');
                    } catch (error) {
                      console.error('Error removing goal:', error);
                      setShowToast(true);
                      setToastMessage('Failed to remove goal. Please try again.');
                    }
                  }}
                  onAddGoal={() => setShowGoalModal(true)}
                  onToggleProgress={async (goalId) => {
                    if (!currentUser) return;
                    const updatedGoals = goals.map(g =>
                      g.id === goalId
                        ? { ...g, current: Math.min(g.current + 1, g.target) }
                        : g
                    );
                    const updatedGoal = updatedGoals.find(g => g.id === goalId);
                    if (updatedGoal) {
                      try {
                        await supabaseService.updateGoal(currentUser.id, goalId, {
                          current: updatedGoal.current
                        });
                        setGoals(updatedGoals);
                      } catch (error) {
                        console.error('Error updating goal:', error);
                        setShowToast(true);
                        setToastMessage('Failed to update goal. Please try again.');
                      }
                    }
                  }}
                  selectedDate={selectedDate}
                  goalProgress={goalProgress}
                />
              </section>
            </div>
          </div>
        </div>
      )}

      {currentView === 'stats' && (
        <StatsView
          habits={habits}
          habitHistory={habitHistory}
          goals={goals}
          goalProgress={goalProgress}
          onBack={() => setCurrentView('home')}
        />
      )}

      {currentView === 'pause' && (
        <PauseView />
      )}
      
      {currentView === 'spaces' && (
        <SpacesView />
      )}

      {currentView === 'settings' && (
        <SettingsView
          onBack={() => setCurrentView('home')}
          onLogout={handleLogout}
        />
      )}

      {showNewHabitModal && (
        <NewHabitModal
          isOpen={showNewHabitModal}
          onClose={() => setShowNewHabitModal(false)}
          onSubmit={(habit) => {
            handleAddHabit(habit);
            setShowNewHabitModal(false);
          }}
        />
      )}

      {showGoalModal && (
        <GoalModal
          isOpen={showGoalModal}
          onClose={() => setShowGoalModal(false)}
          onSubmit={(goal) => {
            handleAddGoal(goal);
            setShowGoalModal(false);
          }}
        />
      )}

      {showToast && (
        <Toast
          message={toastMessage}
          onClose={() => setShowToast(false)}
        />
      )}
    </div>
  );
};