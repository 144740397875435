import { supabase } from '../lib/supabase';
import type { Habit, Goal, HabitCompletion, GoalProgress } from '../types';

interface UserProfile {
  id: string;
  name: string;
  email: string;
  newsletter?: boolean;
  created_at?: string;
  updated_at?: string;
}

export class SupabaseService {
  private static instance: SupabaseService;
  
  private constructor() {}

  public static getInstance(): SupabaseService {
    if (!SupabaseService.instance) {
      SupabaseService.instance = new SupabaseService();
    }
    return SupabaseService.instance;
  }

  // User Profile Methods
  async saveUserProfile(userId: string, profile: Omit<UserProfile, 'id'>) {
    const { data, error } = await supabase
      .from('profiles')
      .upsert(
        { 
          id: userId, 
          name: profile.name || '',
          email: profile.email || '',
          newsletter: profile.newsletter ?? true,
          updated_at: new Date().toISOString()
        },
        { onConflict: 'id' }
      )
      .select()
      .single();

    if (error) {
      console.error('Error saving user profile:', error);
      throw new Error('Failed to save user profile');
    }
    return data;
  }

  async getUserProfile(userId: string) {
    const { data, error } = await supabase
      .from('profiles')
      .select()
      .eq('id', userId)
      .single();

    if (error) throw error;
    return data;
  }

  async updateUserProfile(userId: string, updates: Partial<UserProfile>) {
    const { error } = await supabase
      .from('profiles')
      .update(updates)
      .eq('id', userId);

    if (error) throw error;
  }

  // Habits Methods
  async saveHabit(userId: string, habit: Omit<Habit, 'id' | 'completed'>): Promise<Habit> {
    const { data, error } = await supabase
      .from('habits')
      .insert(
        {
          user_id: userId,
          name: habit.name,
          type: habit.type || 'daily',
          frequency: habit.frequency || 1,
          completed: false,
          reminder_time: habit.reminderTime || null,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }
      )
      .select()
      .single();

    if (error) {
      console.error('Error saving habit:', error);
      throw new Error('Failed to save habit');
    }

    if (!data) {
      throw new Error('No data returned after saving habit');
    }

    return {
      id: data.id,
      name: data.name,
      type: data.type,
      frequency: data.frequency,
      completed: data.completed,
      reminderTime: data.reminder_time
    };
  }

  async getHabits(userId: string): Promise<Habit[]> {
    const { data, error } = await supabase
      .from('habits')
      .select()
      .eq('user_id', userId);

    if (error) throw error;
    return data || [];
  }

  async updateHabit(userId: string, habitId: string, updates: Partial<Habit>) {
    const { error } = await supabase
      .from('habits')
      .update(updates)
      .eq('id', habitId)
      .eq('user_id', userId);

    if (error) throw error;
  }

  async deleteHabit(userId: string, habitId: string) {
    const { error } = await supabase
      .from('habits')
      .delete()
      .eq('id', habitId)
      .eq('user_id', userId);

    if (error) throw error;
  }

  // Goals Methods
  async saveGoal(userId: string, goal: Omit<Goal, 'id' | 'current'>): Promise<Goal> {
    const { data, error } = await supabase
      .from('goals')
      .insert(
        {
          user_id: userId,
          name: goal.name,
          type: goal.type || 'Ultimate',
          target: goal.target,
          current: 0,
          deadline: goal.deadline || null,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }
      )
      .select()
      .single();

    if (error) {
      console.error('Error saving goal:', error);
      throw new Error('Failed to save goal');
    }

    if (!data) {
      throw new Error('No data returned after saving goal');
    }

    return {
      id: data.id,
      name: data.name,
      type: data.type,
      target: data.target,
      current: data.current,
      deadline: data.deadline
    };
  }

  async getGoals(userId: string): Promise<Goal[]> {
    const { data, error } = await supabase
      .from('goals')
      .select()
      .eq('user_id', userId);

    if (error) throw error;
    return data || [];
  }

  async updateGoal(userId: string, goalId: string, updates: Partial<Goal>) {
    const { error } = await supabase
      .from('goals')
      .update(updates)
      .eq('id', goalId)
      .eq('user_id', userId);

    if (error) throw error;
  }

  async deleteGoal(userId: string, goalId: string) {
    const { error } = await supabase
      .from('goals')
      .delete()
      .eq('id', goalId)
      .eq('user_id', userId);

    if (error) throw error;
  }

  // Progress Methods
  async saveHabitCompletion(userId: string, completion: Omit<HabitCompletion, 'id'>) {
    const { data, error } = await supabase
      .from('habit_completions')
      .insert({ user_id: userId, ...completion })
      .select()
      .single();

    if (error) throw error;
    return data;
  }

  async getHabitCompletions(userId: string, habitId: string): Promise<HabitCompletion[]> {
    const { data, error } = await supabase
      .from('habit_completions')
      .select()
      .eq('habit_id', habitId)
      .eq('user_id', userId);

    if (error) throw error;
    return data || [];
  }

  async saveGoalProgress(userId: string, progress: Omit<GoalProgress, 'id'>) {
    const { data, error } = await supabase
      .from('goal_progress')
      .insert({ user_id: userId, ...progress })
      .select()
      .single();

    if (error) throw error;
    return data;
  }

  async getGoalProgress(userId: string, goalId: string): Promise<GoalProgress[]> {
    const { data, error } = await supabase
      .from('goal_progress')
      .select()
      .eq('goal_id', goalId)
      .eq('user_id', userId);

    if (error) throw error;
    return data || [];
  }
}